<template>
  <div class="py-5 home-products">
    <div class="container home-products__container">

      <h4 class="text-center title-h2 mb-4">{{ title }}</h4>
      <div class=" page-accessories" v-if="products?.nodes">

          <Carousel
              v-bind="carouselConfig"
              class=""
              ref="carousel"
              @init="handleInit"
          >
            <Slide v-for="(product, index) in products?.nodes" :key="index">
              <ProductCardItem
                  :product="product"
                  :index="index"
                  :class="`product-card-item--${index}`"/>

            </Slide>

            <template #addons>
              <Navigation />
              <Pagination/>
            </template>
          </Carousel>
      </div>
    </div>
        <div class="text-center  pt-5" >
          <nuxt-link :to="parseLink(url)" class="more-link red-underline red-underline--short">לכל המוצרים</nuxt-link>
        </div>
  </div>
</template>
<script lang="ts" setup>


const {currentPage} = await useGlobalStore();
const {title, products, url} = currentPage?.template?.homepage?.products;
const carousel = ref(null);
const slide = ref(0)



const carouselConfig = {
  dir:"rtl",
  itemsToShow:1,
  breakpoints: {
    100:{
      itemsToShow: 2.2,
      gap: 10,
      wrapAround: true
    },
    800: {
      itemsToShow: 5,
      snapAlign: "start",
      gap: 10,
    },
  },
}
const handleInit = () => {

  if (process.client){
    const carouselSlide = document.querySelectorAll('.carousel__slide');


  }
}
onMounted(() => {

})

</script>
<style lang="scss" >
.home-products{
  &__container{
    @media screen and (max-width: 800px) {
      width: 100%;
      padding: 0!important;
    }
  }
  .product-inner .product-image img {
    @media screen and (max-width: 800px) {
      max-height: initial;
    }

  }
  .carousel{
    &__slide{
      align-items: flex-start;
    }
    &.is-rtl{
      .carousel__prev{
        right: -70px;
        @media screen and (max-width: 800px) {
          right: 15%;
          top:65%;
        }
      }
      .carousel__next{
        left: -70px;
        top: 50%;
        @media screen and (max-width: 800px) {
          left: 15%;
          top:65%;

        }
      }
      .carousel__slide--next, .carousel__slide--prev{
        @media screen and (max-width: 800px) {
         pointer-events: none;
        }
      }
    }
    .carousel__pagination{
      bottom: -25px;
    }
    &__slide{
      &--active{
        @media screen and (max-width: 800px) {
          transform: scale(1.1)!important;
          z-index: 100;
          opacity: 1!important;
        }
      }
      &--visible{
        @media screen and (max-width: 800px) {
          transform: scale(0.9);
          z-index: 100;
          opacity: 0.7;
          transition: all .3s ease-in-out;
        }
      }

    }
    .carousel__pagination-button {
      &--active {
        &::after {
          opacity: 1 !important;
          background-color: var(--red)!important;
        }
      }
      &::after {
        background-color: grey;
        opacity: 0.5;
      }
    }
  }
  .product{
    &-style{
      padding: 20px 0;
      .show-more-bt{
        display: none;
      }
    }
    .made-in-israel-tag {
      @media screen and (max-width: 800px) {
        width: 25px !important;
      }


    }
    &-inner{
      margin-top: 0;
      .woocommerce-loop-product__title{
        font-size: 20px;
        @media screen and (max-width: 800px) {
          font-size: 11px;
          min-height: 30px;
        }
      }
      .price{
        @media screen and (max-width: 800px) {
          font-size: 14px;
          margin: 3px 0;
          display: block;
        }
      }
      .bt_red{
        a{

          @media screen and (max-width: 800px) {
            font-size: 11px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
